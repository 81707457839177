/**************************************
    Default Styles
***************************************/

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../css/slick.css';
@import '../css/slicktheme.css';
@import '../css/animation.css';


@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';


/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/service-details';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

/* Terms and Conditions Styles */
.rwt-terms-area {
  .terms-content {
    padding: 40px 0;
    
    .title {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: 700;
      color: var(--color-primary);
    }

    .description {
      margin-bottom: 40px;
      color: var(--color-body);
      font-size: 16px;
    }

    .terms-section {
      margin-bottom: 40px;

      h3 {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 600;
        color: var(--color-primary);
      }

      p {
        margin-bottom: 20px;
        color: var(--color-body);
        font-size: 16px;
        line-height: 1.8;
      }

      ul {
        padding-left: 20px;
        
        li {
          margin-bottom: 10px;
          color: var(--color-body);
          font-size: 16px;
          line-height: 1.8;
          position: relative;
          
          &:before {
            content: "•";
            color: var(--color-primary);
            font-weight: bold;
            position: absolute;
            left: -20px;
          }
        }
      }
    }
  }
}

