/*----------------------------
    Advance Pricing Table  
------------------------------*/
.advance-pricing {
    background: var(--color-blackest);
    border-radius: 10px;
    .pricing-left {
        height: 100%;
        background: var(--color-lessdark);
        padding: 60px 50px;
        border-radius: 10px 0 0 10px;
        .main-title {
            margin-bottom: 10px;
            text-align: center;
        }
        .description {
            text-align: justify;
            word-spacing: 0.5px;
        }
        .price-wrapper {
            .price-amount {
                font-size: 60px;
                text-align: center;
                display: block;
                color: var(--color-white);
                font-weight: 700;
                sup {
                    font-size: 16px;
                    top: -34px;
                }
            }
        }
        .btn-default {
            width: 100%;
            margin: 10px 0;
        }
        .rating {
            text-align: center;
            margin-top: 5px;
            a {
                font-weight: 700;
                color: #f1b304;
                svg {
                    fill: #f1b304;
                }
            }
        }
        .subtitle {
            display: block;
            color: var(--color-body);
            text-align: center;
        }
    }

    .pricing-right  {
        height: 100%;
        // background: var(--color-lessdark);
        padding: 60px 50px;
        border-radius: 0 10px 10px 0;
        .main-title {
            margin-bottom: 10px;
            text-align: center;
        }
        .description {
            text-align: justify;

        }
        .price-wrapper {
            .price-amount {
                font-size: 60px;
                text-align: center;
                display: block;
                color: var(--color-white);
                font-weight: 700;
                sup {
                    font-size: 16px;
                    top: -34px;
                }
            }
        }
        .btn-default {
            width: 100%;
            margin: 10px 0;
        }
        .rating {
            text-align: center;
            margin-top: 5px;
            a {
                font-weight: 700;
                color: #f1b304;
                svg {
                    fill: #f1b304;
                }
            }
        }
        .subtitle {
            display: block;
            color: var(--color-body);
            text-align: center;
        }
    }
}



















