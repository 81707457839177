.section-title {
    .subtitle {
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 18px;
        font-size: 16px;
        border-radius: 4px;
        margin-bottom: 12px;
        display: inline-block;
        padding: 7px 14px;
        background-color: var(--color-blackest);
        box-shadow: var(--shadow-lighter);
        &.radius-rounded {
            border-radius: 100px;
        }
    }
    .title {
        
    }
    .description {
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

   

    .portfolioTitle {
        color: #ffffff;
        // text-align: justify;
        display: block;
        border-bottom: 1px solid var(--color-border);
        padding-top: 19px;
        padding-bottom: 19px;
        // opacity: 0.8;
    }

    
    
    .portfolioDescription {
        color: #ffffff;
        text-align: left;
        letter-spacing: normal; // Adjust letter-spacing to normal for better readability
        line-height: 1.4; // Add line-height for better spacing between lines
        display: flex;
        padding-bottom: 19px;
        padding-left: 5px;
        padding-right: 5px;
        opacity: 0.5; // Increase opacity for better contrast
      
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }

        @media #{$sm-layout} {
            text-align: left;
            padding-left: 15px; // Adjust padding for smaller screens
            padding-right: 15px; 
          }
    }
    .portfolioDescription p {
        color: #ffffff;
        text-align: left;
        display: flex;
        // border-bottom: 1px solid var(--color-border);
        padding-bottom: 19px;
        padding-left: 5px;
        padding-right: 5px;
        // opacity: 0.1;
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }
    p {
        &:empty {
            display: none;
        }
    }

    
}


.section-title-2 {
    .title {
        color: var(--color-body);
        display: block;
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 19px;
        opacity: 0.6;
    }
}










