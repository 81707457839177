// Service Details Page Styles
.rwt-portfolio-details {
    .details-list {
        .title {
            font-size: 36px;
            line-height: 1.4;
            margin-bottom: 30px;
            font-weight: 700;
            color: var(--color-heading);
        }

        .description {
            font-size: 18px;
            line-height: 1.8;
            margin-bottom: 0;
            color: var(--color-body);
        }

        .list-style {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                font-size: 16px;
                color: var(--color-body);

                .feather-check {
                    color: var(--color-primary);
                    margin-right: 10px;
                    font-size: 18px;
                }
            }
        }

        .content-left,
        .content-right {
            .title {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }

        .contact-button {
            .btn-default {
                padding: 20px 40px;
                font-size: 18px;
                font-weight: 500;
                border-radius: 6px;
                transition: all 0.3s ease;
                display: inline-block;
                background: var(--color-primary);
                color: var(--color-white);
                border: 2px solid var(--color-primary);

                &:hover {
                    background: transparent;
                    color: var(--color-primary);
                }
            }
        }
    }

    .thumbnail {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;

        img {
            width: 100%;
            height: auto;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    @media (max-width: 767px) {
        .details-list {
            .title {
                font-size: 28px;
            }

            .description {
                font-size: 16px;
            }

            .list-style li {
                font-size: 14px;
            }

            .content-left,
            .content-right {
                .title {
                    font-size: 20px;
                }
            }
        }
    }
} 