/*--------------------------
    Navigation Styles 
----------------------------*/
.mainmenu-nav {
    .mainmenu {
        display: flex;
        margin: 0;
        padding: 0;
        > li {
            > a {
                color: var(--color-body);
                font-size: 16px;
                font-weight: 500;
                padding: 0 17px;
                display: block;
                height: 80px;
                line-height: 80px;
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            &.has-droupdown {
                .submenu {
                    min-width: 240px;
                    height: auto;
                    position: absolute;
                    top: 90%;
                    left: 0;
                    z-index: 90;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                    padding: 12px 0;
                    transition: 0.3s;
                    border-radius: 0 0 10px 10px;
                    background-color: var(--color-blackest);
                    box-shadow: var(--shadow-lighter);
                    li {
                        a {
                            font-weight: 500;
                            padding: 5px 20px;
                            font-size: 14px;
                            display: block;
                            color: var(--color-body);
                            margin: 0 10px;
                            border-radius: 3px;
                            @extend %transition;
                            &:hover {
                                color: var(--color-primary);
                                background: var(--color-darker);
                            }
                        }
                    }
                }
    
                &:hover {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

            &.with-megamenu { 
                position: static;

                .rn-megamenu {
                    position: absolute;
                    transition: 0.3s;
                    top: 90%;
                    width: 100%;
                    left: 0;
                    right: 0;
                    padding: 0 15px;
                    visibility: hidden;
                    opacity: 0;
                    .wrapper {
                        border-radius: 0 0 10px 10px;
                        background-color: var(--color-blackest);
                        box-shadow: var(--shadow-lighter);

                        .mega-menu-item {
                            padding: 22px 10px;
                            border-right: 1px solid var(--color-extra04);
                            height: 100%;

                            li {
                                a {
                                    font-weight: 500;
                                    padding: 5px 14px;
                                    font-size: 15px;
                                    display: block;
                                    color: var(--color-body);
                                    margin: 0 10px;
                                    border-radius: 4px;
                                    transition: 0.3s;
                                    display: flex;
                                    align-items: center;

                                    .rn-badge-card {
                                        margin-left: 10px;
                                    }

                                    &:hover {
                                        color: var(--color-primary);
                                        background: var(--color-darker);
                                    }
                                }
                            }
                        }
                    }
                }

                .single-mega-item {
                    &:last-child {
                        .mega-menu-item {
                            border-right: 0 none;
                        }
                    }
                }

                &:hover {
                    .rn-megamenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

        }
    }
}

.navbar.header-default {
    background-color: var(--color-darker) !important;
    border-bottom: 1px solid var(--color-border);

    .navbar-brand {
        color: var(--color-white);
        h2 {
            margin: 0;
        }
    }

    .nav-link {
        color: var(--color-white) !important;
        font-size: 16px;
        padding: 0 20px;
        transition: all 0.3s ease;

        &:hover {
            color: var(--color-primary) !important;
        }
    }

    .navbar-toggler {
        border-color: var(--color-white);
        
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
}

// Sticky header styles
.navbar.header-default.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    animation: headerSticky 0.95s ease forwards;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

