.main-container {
  padding: 20px;
}

@media (max-width: 768px) {
  .box {
    flex-direction: column; /* Stack box parts vertically on smaller screens */
  }
}

.row h2{
  margin-top: 20px;
}
.row h3{
  margin-top: 20px;
  font-style: inherit;
}

.box {
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff; */
}

.box-part {
  flex: 1;
  padding: 20px;
}

.content {
    flex: 2;
  }
  
  .content h2 {
    margin-top: 0;
  }
  
  .content ul {
    padding-left: 20px;
    margin: 0;
    list-style-type: disc;
  }

/* .left {
  border-right: 1px solid #ddd;
}

.right {
  border-left: 1px solid #ddd;
} */

.box h2 {
  margin-top: 0;
}

.left p {
    font-size: 2.4rem; /* Adjust the font size as needed */
    line-height: 1.5; /* Adjust the line height for better readability */
  }
  
  .image {
    flex: 1;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .right img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .content {
    flex: 1;
    padding-left: 20px; /* Add padding to separate from image */
  }
  
  .content ul {
    margin: 0;
    padding: 0;
  }
  
  .content ul li {
    list-style-type: disc;
    font-size: 25px; /* Adjust the font size as desired */
  }


  .row3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:url('productImages/Untitled-1 1.png') ; /* Adjust the path if necessary */
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    min-height: 50vh; /* Ensure it covers the full viewport height */
  }
  
  .box3 {
    display: flex;
    gap: 20px;
  }
  
  .card3 {
  position: relative; /* Ensure relative positioning for absolute child elements */
  width: 350px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 20px; /* Add margin to create space between cards */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color and text color change */
  }

  .card3:hover {
    background-color: #3498db; /* Change to blue color when hovered */
    color: #fff; /* Change text color to white when hovered */
  }
  
  
  .small-image {
    width: 90px; /* Adjust as necessary */
    height: 90px; /* Adjust as necessary */
    object-fit: cover;
    background-image: url('productImages/a.png');
    background-size: cover;
    background-position: center;
    border-radius: 50%; /* Optional: Make it circular */
    position: absolute;
    top: -45px; /* Half of the height of the image */
    left: 50%; /* Position it at the center of the card */
    transform: translateX(-50%); /* Adjust horizontal position */

  }
  
  .card3 h2 {
    margin-top: 20%;
    font-size: 22px;
    /* Ensure there's some margin */
   color: #3061e7; /* Ensure the text color is visible */
   font-weight: bold;
  }
  
  .card3 p {
    margin-top: 10px;
    font-size: 18px; /* Increase the font size */
   font-weight: bold; /* Make the font bold */
   margin-bottom: auto; /* Make sure it takes up remaining space */
   color: #333; /* Ensure the text color is visible */
   text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* Add a black shadow effect */
  }
  
  .card3 button {
    padding: 8px 16px;
    background-color: #1843cee1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    margin-top: 10px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for color change */
  }
  
  .card3 button:hover {
    background-color: #00cc00; /* Change to green color when hovered */
  }
  
  
  .row4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    /* background-image: url('productImages/Rectangle\ 23.png'); */
  }
  
  .box4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card4 {
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 10px;
    background-image: url('productImages/Rectangle\ 19.png');
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

  .outer-image {
    position: relative;
    display: inline-block; /* Ensure block-level positioning */
  }
  
  .inner-image {
    position: absolute;
    top: 50%; /* Position the inner image at the center vertically */
    left: 50%; /* Position the inner image at the center horizontally */
    transform: translate(-50%, -50%); /* Move the inner image back by half its size */
    z-index: 1; /* Ensure the inner image appears above the outer image */
  }

  .card4 h2{
    margin-top: 20%;
    font-size: 22px;
   color: #3061e7; /* Ensure the text color is visible */
   font-weight: bold;
  }
  .card4 p {
    margin-top: 10px;
    font-size: 18px; /* Increase the font size */
   font-weight: bold; /* Make the font bold */
   margin-bottom: auto; /* Make sure it takes up remaining space */
   color: #333; /* Ensure the text color is visible */
   text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* Add a black shadow effect */
  }
  
 .row4 h2 {
    color: rgb(41, 99, 185);
    margin-top: 20px; /* Space above heading */
    margin-bottom: 20px; /* Space below heading */
  }
  
  /* .row5 {
    display: flex;
   justify-content: center;
   align-items: center;
   background-image: 
    url('productImages/Rectangle 31.png'),
    url('productImages/333bea5f11 1.png'); 
   background-size: cover, cover; 
   background-position: center, center; 
   padding: 60px 0;
   min-height: 50vh; 
  }

  .row5 h2 {
    margin-top: -50px;
    margin-left: 30px; 
    margin-bottom: 400px;
    color: white; 
  } */

  .row5 {
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    align-items: center; /* Center items horizontally */
    background-image: 
      url('productImages/Rectangle 31.png'),
      url('productImages/333bea5f11 1.png'); /* Adjust the paths if necessary */
    background-size: cover, cover; /* Ensure both images cover the container */
    background-position: center, center; /* Center both images */
    padding: 60px 20px; /* Adjust padding as necessary */
    min-height: 50vh; /* Ensure it covers the full viewport height */
  }
  
  .row5 h2 {
    margin-top: -25px;
    margin-bottom: 20px; /* Space between heading and image */
    color: white; /* Adjust color as necessary */
    text-align: center; /* Center text */
    
  }

 
  
  .box5 {
    display: flex;
    justify-content: center; /* Center the image horizontally */
    width: 100%; /* Ensure the box takes full width */
      flex-wrap: wrap; /* Allow wrapping for smaller screens */
      position: relative;
      margin-left: 20%;
      
  }

.box-part .content {
    flex: 1;
    margin-right: 10px;  
}

.box-part .content p {
  font-size: 25px; /* Adjust the font size as needed */
  color: #110303;
  background-color: #fff;
}

  .centered-image {
    margin-top: 10%;
    max-width: 50%; /* Adjust the size of the image */
    height: auto; /* Maintain aspect ratio */
    margin-left: 3%; /* Space on the right */
    max-height: 300px; /* Adjust the height as needed to ensure it’s smaller */
  
  }
  @media only screen and (max-width: 600px) {
    .row5 {
        padding: 30px 10px; /* Adjust padding for smaller screens */
    }

    .row5 h2 {
        margin-left: 10px; /* Adjust margin for smaller screens */
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }

    .box5 {
        margin-left: 0; /* Adjust margin for smaller screens */
        flex-direction: column; /* Stack elements vertically on small screens */
        align-items: center; /* Center elements */
    }

    .box-part .content p {
        font-size: 18px; /* Further reduce font size for smaller screens */
    }

    .centered-image {
        max-width: 100%; /* Ensure the image takes the full width */
        margin: 0 auto; /* Center the image */
        height: auto; /* Maintain aspect ratio */
    }
}
  /* .row6{
   background-image: url('productImages/b98078d94e\ 1.png');
   display: flex;
   flex-direction: column;
   padding: 20px 0;
   min-height: 50vh; 
  }
  .row6 h2{
    margin-top: 3%;
    margin-left: 10%;
    color: rgb(41, 99, 185);
  }
  .box6 p{
    margin-left: 10%;
  } */

  .row6 {
    background-image: url('productImages/b98078d94e\ 1.png');
    display: flex;
    flex-direction: column;
    padding: 40px 20px; /* Adjust padding as necessary */
    min-height: 50vh; /* Ensure it covers half the viewport height */
  
  }
  
  .row6 h2 {
    margin-top: 3%;
    margin-left: 8%;
    color: rgb(41, 99, 185);
  }
  
  .box6 {
    width: 40%; /* Use half of the container width */
  }
  
  .box6 p {
    margin-left: 20%;
    font-size: 2.5rem; /* Adjust font size for larger text */
    line-height: 1.6; /* Adjust line height for readability */
    color: #1f1c1c; /* Dark color for the text */
    font-weight: bold; /* Make the text bold */
  }
  